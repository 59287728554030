import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';

import { cssShadowElevation } from './utils';

const shadowColour = `188deg 13% 68%`;

// Custom menu theme for header menu
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);
const headerMenuTheme = defineMultiStyleConfig({
  variants: {
    headerMenu: definePartsStyle({
      item: {},
      icon: {
        pl: 2,
        color: 'teal.500',
        transform: 'scale(1.2)',
      },
      list: {
        zIndex: 600,
        borderRadius: 0,
        borderBottomLeftRadius: 'md',
        borderTop: 'none',
        borderRight: 'none',
      },
      groupTitle: {
        color: 'gray.600',
      },
    }),
  },
});

export const theme = extendTheme({
  initialColorMode: 'light',
  breakpoints: {
    // These should match the breakpoints in sparx-design/units.css
    base: '0px',
    // xs: We dont use the xs breakpoint
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
    '2xl': '1920px',
  },
  fonts: {
    body: "'DM Sans', sans-serif",
    heading: "'DM Sans', sans-serif",
  },
  styles: {
    global: {
      body: {
        letterSpacing: '-0.005em',
      },
      button: {
        letterSpacing: '-0.005em',
      },
    },
  },
  colors: {
    pageBackground: '#F2F9FA',
    buttonTeal: {
      50: '#E6FFFA',
      100: '#E6FFFA', //'#81E6D9',
      200: '#B2F5EA', //'#4FD1C5',
      300: '#B2F5EA', //'#38B2AC',
      400: '#319795',
      500: '#2C7A7B',
      600: '#285E61',
      700: '#234E52',
      800: '#1D4044',
      // 900: '#',
    },
    buttonBlue: {
      '50': '#D8E4F3',
      '100': '#83ACD8',
      '200': '#6094CD',
      '300': '#3D7DC2',
      '400': '#31649B',
      '500': '#254B74',
      '600': '#19324D',
      '700': '#0C1927',
    },
  },
  shadows: {
    elevationLow: cssShadowElevation('low', shadowColour),
    elevationMedium: cssShadowElevation('medium', shadowColour),
    elevationHigh: cssShadowElevation('high', shadowColour),
  },
  components: {
    Heading: {
      baseStyle: {
        color: 'blue.900',
      },
    },
    Menu: headerMenuTheme,
    Checkbox: {
      baseStyle: {
        control: {
          bg: 'white',
        },
      },
    },
  },
});
